import service from './Service';
import config from "../helpers/config";

const END_POINT = '/entities';

const getUserData = (id) => {
    return service.get(END_POINT + '/users/by-id-with-data/'+ id,  { useCache: true })
};

const searchUser = (val) => {
    return service.get(END_POINT + '/users/basic-search/'+ val + '?pageSize=10&pageNumber=1')
};

const getUserById = (id) => {
    return service.get(END_POINT + '/users/by-id/'+ id)
};

const isAdminLoggedIn = async () => {
    const {data} = await service.post(config.apiUrl + '/entities/users/check-admin');
    const isAdmin = data.message;
    return isAdmin === "true" ? true : false;     
}

export {
    getUserData,
    searchUser,
    getUserById,
    isAdminLoggedIn
}