import InformationCard from "@shared/InformationCard/InformationCard.vue"
import FocusedInformationCard from "@shared/FocusedInformationCard/FocusedInformationCard.vue"
import { getUserData } from '@services/User.api'
import { unBookmarkImage, bookmarkImage } from '@services/Images.api'
import { unBookmarkArticle, bookmarkArticle } from '@services/Articles.api'
import { unBookmarkQuery } from '@services/Filter.api'
import config from '@/helpers/config'
import { mapGetters, mapState, mapMutations } from "vuex";
import { removeByAttr, getEntitiesList, queryStringToObject, removeUndefined } from '@mixins/helpers'
import InformationCardMixin from '@mixins/informationCardMixin' 

export default {
    name:"Profile",
    mixins: [InformationCardMixin],

    components: {
        InformationCard,
        FocusedInformationCard
    },

    data() {
        return {
            apiUrl: config.apiUrl,
            showBookmarkedArticles: false,
            showBookmarkedImages: false,
            showBookmarkedQueries: true,

            activeArticle: null,
            activeImage: null,

            isActiveArticleBookmarked: true,
            isActiveImageBookmarked: true,

            bookmarkedArticles: [],
            bookmarkedImages: [],
            bookmarkedQueries: [],
            isImageBookmarkLoading: false,
            isArticleBookmarkLoading: false,

            userName: ''
        }
    },

    async created() {
        if (this.isLoggedIn) {
            await getUserData(this.user.id).then(
                response => {
                    this.userName = this.user.firstName + " " + this.user.lastName
                    this.setArticlesArray(response.data.payload.articles)
                    this.setImagesArray(response.data.payload.images)
                    this.bookmarkedQueries = response.data.payload.queries
                }, err => {
                    console.log('err ', err);
                }
            )
        } else {
            this.$router.push({ name: 'signin' })
        }
    },

    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapState('auth', ['user']),
        ...mapState('loaders', ['isPageLoading'])
    },

    methods: {
        ...mapMutations('notification', ['notify']),

        toggleArticles() {
            this.showBookmarkedArticles = !this.showBookmarkedArticles 
        },
        toggleImages() {
            this.showBookmarkedImages = !this.showBookmarkedImages
        },
        toggleQueries() {
            this.showBookmarkedQueries = !this.showBookmarkedQueries
        },

        setArticlesArray(articlesArray) {
            this.bookmarkedArticles = articlesArray.map(article => {
                return {
                    id: article.id,
                    isSafirContent: article.sourceName === "السفير",
                    src: this.apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/400',
                    lazysrc: this.apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/100',
                    title: article.title,
                    authorTitle: "إسم الكاتب",
                    authorName: removeUndefined(article.authorName),
                    sourceName: removeUndefined(article.sourceName),
                    date: article.year + " - " + article.month + " - " + article.day,
                    entities: getEntitiesList(article.entities),
                    description: article.content,
                    direction: article.languageName === 'EN' | 'FR' ? 'ltr' : 'rtl',
                    newPdfPath: article.newPdfPath,
                    oldPdfPath: article.oldPdfPath,
                }
            })
        },

        setImagesArray(imagesArray) {
            this.bookmarkedImages = imagesArray.map(image => {
                return {
                    id: image.id,
                    isSafirContent: false,
                    src: this.apiUrl + '/entities/images/raw/' + image.fileName + '/400',
                    lazysrc: this.apiUrl + '/entities/images/raw/' + image.fileName + '/100',
                    title: image.caption,
                    authorTitle: "إسم المصور",
                    authorName: removeUndefined(image.photographerName),
                    sourceName: removeUndefined(image.sourceName),
                    date: image.year + " - " + image.month + " - " + image.day,
                    entities: getEntitiesList(image.entities),
                    description: image.description,
                    direction: image.languageName === 'EN' | 'FR' ? 'ltr' : 'rtl',
                    fileName: image.fileName
                }
            })
        },

        async unBookmarkArticle(data) {
            await unBookmarkArticle(data).then(
                () => {
                    removeByAttr(this.bookmarkedArticles, 'id', data.articleId)
                    this.isActiveArticleBookmarked = false
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async bookmarkArticle(data) {
            await bookmarkArticle(data).then(
                () => {
                    this.bookmarkedArticles.push(this.activeArticle)
                    this.isActiveArticleBookmarked = true
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async bookmarkImage(data) {
            await bookmarkImage(data).then(
                () => {
                    this.bookmarkedImages.push(this.activeImage)
                    this.isActiveImageBookmarked = true
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async unBookmarkImage(data) {
            await unBookmarkImage(data).then(
                () => {
                    removeByAttr(this.bookmarkedImages, 'id', data.imageId)
                    this.isActiveImageBookmarked = false
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        hideFocusedImage() {
            this.activeImage = null
        },

        hideFocusedArticle() {
            this.activeArticle = null
        },

        async toggleBookmarkArticle(id) {
            if (this.isLoggedIn) {
                var data = {}

                this.isArticleBookmarkLoading = true
                if (this.isArticleBookmarked(id)) {
                    data = {
                        userId: this.user.id, 
                        articleId: id
                    }
                    await this.unBookmarkArticle(data)
                } else {
                    data = {
                        userId: this.user.id, 
                        articleId: this.activeArticle.id
                    }

                    await this.bookmarkArticle(data)
                }
                this.isArticleBookmarkLoading = false
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        isArticleBookmarked(id) {
            return this.bookmarkedArticles.filter(article => article.id === id).length > 0
        },

        async toggleBookmarkImage(id) {
            if (this.isLoggedIn) {
                var data = {}
                this.isImageBookmarkLoading = true
                if (this.isImageBookmarked(id)) {
                    data = {
                        userId: this.user.id, 
                        imageId: id
                    }

                    await this.unBookmarkImage(data)
                } else {
                    data = {
                        userId: this.user.id, 
                        imageId: this.activeImage.id
                    }

                    await this.bookmarkImage(data)
                }
                this.isImageBookmarkLoading = false
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        isImageBookmarked(id) {
            return this.bookmarkedImages.filter(image => image.id === id).length > 0
        },

        setActiveArticle(article) {
            this.activeArticle = article

            // if (this.isArticleBookmarked(article.id)) {
            //     this.isActiveArticleBookmarked = true
            // } else {
            //     this.isActiveArticleBookmarked = false
            // }

            // window.scrollTo(0,0);
        },

        setActiveImage(article) {
            this.activeImage = article

            // if (this.isImageBookmarked(article.id)) {
            //     this.isActiveImageBookmarked = true
            // } else {
            //     this.isActiveImageBookmarked = false
            // }

            // window.scrollTo(0,0);
        },

        // isArticleBookmarked(id) {
        //     return this.bookmarkedArticles.filter(article => article.id === id).length > 0
        // },

        async downloadImage() {
            if (this.isLoggedIn) {
                var fileName = this.activeImage.fileName

                if (fileName) {
                    let url = config.apiUrl + '/entities/images/raw/' + fileName
                    window.open(url)
                } else {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        async downloadArticle() {
            if (this.isLoggedIn) {
                var pdfPath = this.activeArticle.newPdfPath ? this.activeArticle.newPdfPath : this.activeArticle.oldPdfPath
                
                if (pdfPath) {
                    let url = config.apiUrl + '/entities/articles/raw/' + pdfPath
                    window.open(url)
                } else {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        goToQuery(query) {
            var searchQuery = queryStringToObject(query.query)

            this.$router.push({
                name: 'summary',
                query: searchQuery
            })
        },

        async unbookmarkQuery(query) {
            var data = {
                userId: this.user.id,
                name: query.name
            }

            await unBookmarkQuery(data).then(
                () => {
                    removeByAttr(this.bookmarkedQueries, 'name', data.name)
                    this.notify({message: 'query unbookmarked', show: true})
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        }
    }
}